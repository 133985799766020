@import "../../scss/module";

.text {
  &:not([class*="text-white"]) {
    h3 {
      &:not(:has([style])) {
        &,
        & * {
          color: var(#{--bs-tertiary}) !important;
        }
      }
    }

    a {
      color: var(#{--bs-primary});
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  img {
    max-width: 100%;
  }
  [data-paperturn] {
    height: 600px;
  }
}
